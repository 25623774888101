import Hammer from 'hammerjs';

window.addEventListener('load', (): void => {
  const desktopView = (): boolean => window.innerWidth > 675;

  const collapses: HTMLCollectionOf<Element> = document.getElementsByClassName('peer/collapse');
  const slides: HTMLCollectionOf<Element> = document.getElementsByClassName('slide');
  const bullets: HTMLCollectionOf<Element> = document.getElementsByClassName('bullet');

  const toggleCollapse = (index: number = 0): void => {
    const newCollapse: Element = collapses.item(index);
    const newSlide: Element = slides.item(index);
    const newBullet: Element = bullets.item(index);

    if (newCollapse.classList.contains('active')) {
      return;
    }

    const currentCollapseIndex: number = Array.from(collapses).findIndex((collapse: Element) => collapse.classList.contains('active'));

    if (currentCollapseIndex >= 0) {
      const currentCollapse: Element = collapses.item(currentCollapseIndex);

      currentCollapse.nextElementSibling.classList.add('delay-[400ms]');
      currentCollapse.classList.remove('active');

      const currentSlide: Element = slides.item(currentCollapseIndex);

      currentSlide.classList.add('duration-0');
      currentSlide.classList.remove('duration-[500ms]', 'active');

      const currentBullet: Element = bullets.item(currentCollapseIndex);
      currentBullet.classList.remove('active');
    }

    newCollapse.nextElementSibling.classList.remove('delay-[400ms]');
    newCollapse.classList.add('active');

    newSlide.classList.remove('duration-0');
    newSlide.classList.add('duration-[500ms]', 'active');

    newBullet.classList.add('active');
  };

  Array.from(slides).forEach((slide: HTMLElement) => {
    const hammer: HammerManager = new Hammer(slide);

    hammer.on('swiperight', (event: HammerInput) => {
      if (!event.isFinal || desktopView()) {
        return;
      }

      const index: number = Array.from(collapses).findIndex((collapse: Element) => collapse.classList.contains('active'));

      toggleCollapse(!index ? collapses.length - 1 : index - 1);
    });

    hammer.on('swipeleft', (event: HammerInput) => {
      if (!event.isFinal || desktopView()) {
        return;
      }

      const index: number = Array.from(collapses).findIndex((collapse: Element) => collapse.classList.contains('active'));

      toggleCollapse(index + 1 >= collapses.length ? 0 : index + 1);
    });
  });

  Array.from(collapses).forEach((collapse: HTMLElement, index: number) => {
    collapse.addEventListener('click', () => {
      toggleCollapse(index);
    });

    collapse.addEventListener('keydown', (event: KeyboardEvent) => {
      if (event?.key?.toLowerCase() !== 'enter') {
        return;
      }

      toggleCollapse(index);
    });
  });

  Array.from(bullets).forEach((collapse: HTMLElement, index: number) => {
    collapse.addEventListener('click', () => {
      toggleCollapse(index);
    });
  });
});
